import * as RadixTabs from '@radix-ui/react-tabs';
import classNames from 'classnames';
import { styledList, styledListItem } from './Tabs.css';

// documentation https://www.radix-ui.com/docs/primitives/components/tabs
export const Root = (props: RadixTabs.TabsProps) => {
  return <RadixTabs.Root {...props} />;
};
export const List = (props: RadixTabs.TabsListProps) => {
  return (
    <RadixTabs.List
      {...props}
      className={classNames(props?.className, styledList)}
    />
  );
};

export const Trigger = (props: RadixTabs.TabsTriggerProps) => {
  return (
    <RadixTabs.Trigger
      {...props}
      className={classNames(props?.className, styledListItem)}
    />
  );
};

export const Content = (props: RadixTabs.TabsContentProps) => {
  return <RadixTabs.Content {...props} />;
};
